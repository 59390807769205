// @flow
import ROUTES_PATH from 'domain/router/routesPathConfig';
import { generatePath } from 'react-router-dom';
import { getLabel, DEFAULT_LABEL, getDokkaAlias } from 'labels/hosts';
import cognitoRedirectUrls from "amplify/cognitoRedirectUrls.json";


let label = getLabel();
if (label === DEFAULT_LABEL) {
  label = getDokkaAlias();
}
const env = process.env.REACT_APP_ENV || 'production';
export const cognitoAuthAppUrl = cognitoRedirectUrls[env][label].authAppUrl;
export const cognitoCookieDomain = cognitoRedirectUrls[env][label].cookieDomain;

// in case of IDp initiated login app starts at /idp route
// redirectSignIn config value must be set to domain/idp in this only case
const IDpRoute = generatePath(ROUTES_PATH.AUTH_IDP_LOGIN.absolute);
const signInRedirectUrl = window.location.pathname === IDpRoute ? window.location.origin + IDpRoute : cognitoAuthAppUrl;

export default {
  domain: process.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN,
  scope: ['phone', 'email', 'openid'],
  redirectSignIn: signInRedirectUrl,
  redirectSignOut: cognitoAuthAppUrl,
  responseType: process.env.REACT_APP_COGNITO_RESPONSE_TYPE,
};
