// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { assignOrganizationUser, revokeOrganizationUser } from 'domain/organizationUser';

import AssigmentButton from 'pages/configurations/components/AssigmentButton';

import type { EnhancedUser } from 'domain/organizationUser/types.js.flow';

type TProps = {
  user: EnhancedUser,
  companyId: string,
  isDisabledFn: (user: EnhancedUser) => boolean,
};

const ActionCell: React$StatelessFunctionalComponent<TProps> = ({ user, companyId, isDisabledFn }) => {
  const dispatch = useDispatch();

  const handleUserAssignment = useCallback(() => {
    const { companies_id: companiesId, id } = user;
    const assigned = companiesId.has(companyId);
    if (assigned) dispatch(revokeOrganizationUser({ data: { id } }));
    else dispatch(assignOrganizationUser({ data: { id } }));
  }, [dispatch, companyId, user]);

  return (
    <AssigmentButton
      disabled={isDisabledFn(user)}
      assigned={user.companies_id.has(companyId)}
      onClick={handleUserAssignment}
    />
  );
};

export default ActionCell;
