// @flow
import { type OrganizationType } from 'domain/organization';
import { ROLES } from 'domain/env/helpers';
import { type CompaniesList } from 'domain/companies/companiesSelector';
import type { User } from 'domain/organizationUser/types';

const getNumberWithDash = (number: string | number) => `${number}`.replace(/^.{3}/, (m) => `${m}-`);

const removeDashFromString = (number: string) => number.replace(/-/, '');

// ** info-main form for company and organization */

export const prepareToForm = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && getNumberWithDash(w));

export const prepareToSubmit = (data: OrganizationType) =>
  data.update('whatsapp_phone', (w) => w && removeDashFromString(w));

export const roleTranslation = (entry) => {
  const { role, limited, restricted, admin } = entry;
  const userRole = admin ? 'administrator' : role;
  switch (userRole) {
    case 'administrator':
      return 'configurations.clients.list.role.administrator';
    case ROLES.accountant: {
      switch (true) {
        case limited && restricted:
          return 'forms.organization.users.position.LimitedRestrictedBookkeeper';
        case limited:
          return 'forms.organization.users.position.limitedBookkeeper';
        case restricted:
          return 'forms.organization.users.position.restrictedBookkeeper';
        default:
          return 'forms.organization.users.position.bookkeeper';
      }
    }
    case ROLES.user: {
      switch (true) {
        case restricted:
          return 'form.organization.users.edit.restricted';
        default:
          return 'form.organization.users.edit.regular';
      }
    }
    case ROLES['confidential-user']: {
      switch (true) {
        case restricted:
          return 'form.organization.users.edit.confidential-restricted';
        default:
          return 'form.organization.users.edit.confidential';
      }
    }
    case ROLES.supplier:
      return 'form.organization.users.edit.supplier';
  }
};

export const getUserDisplayName = (user: User) => {
  const { first_name, last_name } = user;
  const fullName = `${typeof first_name === 'string' ? `${first_name} ` : ''}${last_name || ''}`;
  return fullName;
};

const rolesOrder = [
  'admin',
  'accountant',
  'limited-accountant',
  'restricted-accountant',
  'user',
  'confidential-user',
  'restricted-user',
  'confidential-restricted-user',
  'supplier',
];

export const sortUsers = (users: UserList) => {
  const userGroups = users
    .sort((a, b) => a.display_name.localeCompare(b.display_name))
    .reduce((res, user) => {
      const groupName = user.admin ? 'admin' : user.role;
      const group = res[groupName] || [];
      res[groupName] = [...group, user];
      return res;
    }, {});

  return rolesOrder.reduce((res, role) => {
    const userGroup = userGroups[role] || [];
    return [...res, ...userGroup];
  }, []);
};

export const sortCompanies = (companies: CompaniesList) => companies.sort((a, b) => a.cname.localeCompare(b.cname));
