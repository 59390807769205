// @flow
import { selector } from 'lib/selectors';
import { userAdapter } from './helpers';
import type { UserType } from 'domain/organizationUser/types.js.flow';
import { currentOrganizationSelector } from 'domain/organization/organizationPureSelector';
import type { TRoleModificators } from './types.js.flow';

const env = (state) => state.env;
export const buildSelector = selector(env, (e) => e.get('build'));
export const environmentSelector = selector(env, (e) => e.get('environment'));
export const roleSelector = selector(env, (e) => e.get('role'));
export const roleModificatorsSelector: TRoleModificators = selector(env, (e) => e.get('roleModificators'));
export const isAuthorizedSelector = selector(roleSelector, (r) => Boolean(r));
export const tokenSelector = selector(env, (e) => e.get('dokkaToken'));
export const sagaDataLoadingSelector = selector(env, (e) => e.get('isSagaDataLoading'));

export const TFATokenSelector = selector(env, (e) => e.get('TFADokkaToken'));
export const TFALastPhoneNumbersSelector = selector(env, (e) => e.get('TFALastPhoneNumbers'));
export const TFAEmailSelector = selector(env, (e) => e.get('TFAEmail'));

export const organizationUserIdSelector = selector(env, (e) => e.get('organizationId'));
export const organizationIdSelector = selector(
  organizationUserIdSelector,
  currentOrganizationSelector,
  (mainId, tempId) => tempId || mainId,
);

export const organizationNameSelector = selector(env, (e) => e.get('organizationName'));
export const navigationSelector = selector(env, (e) => e.get('navigation'));
export const backSearchUrlSelector = selector(env, (e) => e.get('backSearchUrl'));
export const persistedBackUrlSelector = selector(env, (e) => e.get('persistedBackUrl'));
export const linkedSidebarWidthSelector = selector(env, (d) => d.get('linkedSidebarWidth'));
export const documentViewSelector = selector(env, (d) => d.get('view'));
export const resetPasswordErrorSelector = selector(env, (d) => d.get('resetPasswordError'));
export const userIdSelector = selector(env, (e) => e.userId);
export const userNamesSelector = selector(env, (e) => ({
  firstName: e.firstName,
  lastName: e.lastName,
}));

export const isAdminSelector = selector(env, (e) => e.superUser);
export const isDokkaSupportSelector = selector(env, (e) => e.isDokkaSupport);

export const rtlEnable = selector(env, (e) => e.get('rtl'));
export const localeSelector = selector(env, (e) => {
  const locale = e.get('locale');
  return locale === 'uk' ? 'en' : locale;
});
export const realLocaleSelector = selector(env, (e) => e.get('locale'));
export const newChatFlowSelector = selector(env, (e) => e.get('newChatFlow'));
export const loadingSelector = selector(env, (e) => Boolean(e.loading));

export const chatUserSelector = selector(env, (e) => e.chatUser);
export const chatTokenSelector = selector(env, (e) => e.chatToken);

export const userSelector = selector(env, userAdapter);
export const isExpiredSelector = selector(env, (e) => e.isExpired);
export const userGUIDSelector = selector(env, (e) => e.userGUID);
export const userPictureSelector = selector(env, (e) => e.picture);
export const permissionsSelector = selector(env, (e) => e.permissions);
export const googleSearchTextSelector = selector(env, (e) => e.googleSearchText);
export const googlePanelStatusSelector = selector(env, (e) => e.googlePanelStatus);
export const appStatusesSelector = selector(env, (e) => e.appStatuses);
export const userFeaturesSelector = selector(env, (e) => e.features);
export const supplierAliasesSelector = selector(env, (e) => e.supplierAliases);
export const cognitoUsernameSelector = selector(env, (e) => e.cognitoUsername);

export const userProfileSelector = selector(env, (e): UserType => ({
  id: e.userGUID,
  first_name: e.firstName,
  last_name: e.lastName,
  email: e.userId,
  phone: e.phone,
  pic: e.picture,
  role: e.role,
  title: e.title,
  username: e.chatUser,
}));

export const hasUserRequireDataSelector = selector(
  tokenSelector,
  userGUIDSelector,
  (token, userGUID) => !!token === !!userGUID,
);

export const helpdeskLinkSelector = selector(env, (e) => e.helpdeskLink);

export const isAllowFreshdeskSelector = selector(env, (e) => e.allowFreshdesk);

export const workSpaceTypeSelector = selector(env, (e) => e.workSpace);
export const organizationBotSelector = selector(env, (e) => e.organizationBot);

export const errorSelector = selector(env, (e) => e.get('error'));
export const cameFromToApproveCategorySelector = selector(
  navigationSelector,
  (navigation) => navigation.getIn(['search', 'params', 'category1'], '').toString() === '46',
); // 46 === To Approve category

export const getConnectionHealthSelector = selector(env, (e) => e.get('connectionHealth'));
