// @flow
import React from 'react';
import { withStyles } from '@mui/styles';
import { type LocationShape, type Match, generatePath } from 'react-router-dom';
import { type Map, Set, type RecordOf } from 'immutable';
import { compose, type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { queryToSearch } from 'domain/router/helpers';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { checkAcceptSignType } from 'domain/documents/helpers';
import { checkIsAllowSpreadJSLicense } from 'labels/hosts';
import { createMemoFn } from 'lib/propHelpers';
import {
  loadingSelector,
  documentViewSelector,
  localeSelector,
  backSearchUrlSelector,
  navigationSelector,
  clearNavigationAction,
  rtlEnable,
  userIdSelector,
  sagaDataLoadingSelector,
} from 'domain/env';
import CONST from 'domain/documents/constants';

import {
  isCurrentUserSignaturePendingSelector,
  isSignApprovalsStartSelector,
  approvalsStatusSelector,
  approvalsAuthorSelector,
} from 'domain/approvals/selectors';
import {
  jeInvoiceTypeSelector,
  referenceSelector,
  journalPublicationWarningsSelector,
  forceErpSyncAction,
  textractForVendorEnabledSelector,
} from 'domain/journal';
import { toggle2WayMatchingForDocumentAction } from 'domain/textract';
import type { ReferenceRecord } from 'domain/journal/types.js.flow';
import type { Navigation } from 'domain/env/types.js.flow';
import ROUTES_PATH from 'domain/router/routesPathConfig';
import {
  documentSelector,
  viewArrangementSelector,
  documentAccept,
  nextDocumentAction,
  documentSignShowDocument,
  documentUpdateViewArrangementAction,
  documentGetViewArrangementAction,
  getDocumentEmailPayloadAction,
  documentScheduleAcceptAction,
  textractEnabledForDocumentSelector,
  justPublishedDocumentSelector,
  documentsJustPublishedAction,
  headerTablesCorrectionAction,
} from 'domain/documents';

import type { AcceptContext } from 'domain/documents/types.js.flow';

import { type DocumentsType } from 'domain/documents/documentsModel';
import {
  CompanyFactory,
  companiesByIdSelector,
  doesCurrentCompanySupportGeneralDocsOnlySelector,
  companyApprovalsFeatureEnabledSelector,
  companySigningsSelector,
  twoWayMatchingFeatureValueSelector,
  type Company,
} from 'domain/companies';
import * as ACL from 'domain/restriction';

import mediaQuery from 'lib/mediaQuery';
import sheet from './sheet';
import Api from 'domain/api';

import { isStatementSelector, isJeLayoutRTLSelector } from 'domain/journal/selectors';
import download from 'lib/download';
import { promisify, isChangeProps } from 'lib/helpers';
import { getIsExcel } from './helpers';
import { withRedirectWhenApprovalsEnd } from 'hooks/approval/useRedirectWhenApprovalsEnd';
import withRouter from 'hoc/withRouter';

// reconciliation
import { requestPopupChangeStatusAction } from 'domain/reconciliation/actions';
import type { TViewArrangement } from './types.js.flow';
import type { SigningsType } from 'domain/organization/types.js.flow';
import { withApiToken } from 'lib/apiTokenKeeper';

import Body, { FULL_WIDTH_VIEW } from './Body';
import DocumentMoreButtonDialogs from 'pages/document/components/DocumentMoreButton/DocumentMoreButtonDialogs';
import DocumentViewDropdown from './components/DocumentViewDropdown';
import DocumentPublishButton from './components/DocumentPublishButton';
import ApprovalButtons from './components/ApprovalButtons';
import EmailWidget from './components/Widgets/Email';
import NoteWidget from './components/Widgets/Note';
import EventsHistoryPanel from 'pages/document/EventsHistoryPanel';
import toast from 'components/Toast';
import LayoutDocument from 'components/mui/Layouts/Document';
import StickySubheader from 'components/mui/Layouts/components/StickySubheader';
import DialogKeyboardHotkeys from 'pages/document/components/Dialog/DialogKeyboardHotkeys';
import DialogSignDocument from 'pages/common/Dialog/DialogSignDocument';
import CircularProgressWithBackdrop from 'components/mui/CircularProgressWithBackdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormControlLabelSwitch } from 'components/mui/Form/FormControl/FormControlLabelSwitch';
import Switch from 'components/mui/Form/Switch/SwitchBase';
import { DocumentContainer, DocumentFooterPanel, FooterDivider } from 'pages/document/components/StyledComponents';
import DocumentViewButton from './components/DocumentViewButton';
import SelectTableButton from './components/SelectTableButton';
import Tooltip from 'components/mui/Tooltip';

import { PublishTypes } from './components/DocumentPublishButton/DropdownButton/config';

import cx from 'classnames';

type Props = {
  jePublicationWarnings: Set<string>,
  company: Company,
  doesCompanySupportGeneralDocsOnly: boolean,
  documentAccept: Dispatch<documentAccept>,
  documentScheduleAccept: Dispatch<documentScheduleAcceptAction>,
  goToNextDocument: Dispatch<nextDocumentAction>,
  // eslint-disable-next-line react/no-unused-prop-types
  clearNavigation: Dispatch<clearNavigationAction>,
  signDocument: Dispatch<documentSignShowDocument>,
  match: {
    // withRouter HOC
    ...$Exact<Match>,
    params: {
      companyId: string,
      documentId: string,
    },
  },
  intl: IntlShape,
  document: DocumentsType,
  loading: number,
  apiToken: string,
  view: Map<string, number>,
  classes: {
    [key: string]: string,
  },
  locale: 'en' | 'he',
  isGranted: (l: number | number[]) => boolean,
  location: {|
    ...$Exact<LocationShape>,
    state: ?{|
      chat?: boolean,
    |},
  |},
  backSearchUrl: {
    params: {
      [key: string]: string,
    },
    query: {
      [key: string]: string | Array<string>,
    },
  },
  navigation: Navigation,
  navigate: any, // withRouter HOC
  // eslint-disable-next-line react/no-unused-prop-types
  rtl: boolean,
  isJeLayoutRTL: boolean,
  isStatement: boolean,
  userId: string,
  jeInvoiceType: string,
  requestPopupChangeStatus: Dispatch<typeof requestPopupChangeStatusAction>,
  signings: RecordOf<SigningsType>,
  isCurrentUserSignaturePending: boolean,
  isSignApprovalsStart: boolean,
  approvalsStatus: string,
  approvalsAuthor: string,
  isApprovalsEnabled: boolean,
  viewArrangement: TViewArrangement,
  loadViewArrangement: Dispatch<typeof documentGetViewArrangementAction>,
  updateViewArrangement: Dispatch<typeof documentUpdateViewArrangementAction>,
  setDocumentsJustPublished: Dispatch<typeof documentsJustPublishedAction>,
  syncErp: Dispatch<typeof forceErpSyncAction>,
  reference: Map<string, ReferenceRecord>,
  getDocumentEmailPaylod: Dispatch<typeof getDocumentEmailPayloadAction>,
  toggle2WayMatchingForDocument: Dispatch<typeof toggle2WayMatchingForDocumentAction>,
  headerTablesCorrection: Dispatch<typeof headerTablesCorrectionAction>,
  textractEnabledForDocument: ?boolean,
  textractForVendorEnabled: ?boolean,
  isSagaDataLoading: boolean,
  twoWayMatchingFeatureEnabled: boolean,
  isAllowRedirectAfterPublish: boolean, // HOC withRedirectWhenApprovalsEnd
  redirectToApprove: () => void, // HOC withRedirectWhenApprovalsEnd
  justPublished?: string,
};

type OpenModalNames =
  | 'tagsModal'
  | 'notesModal'
  | 'duplicateModal'
  | 'signModal'
  | 'rejectModal'
  | 'deleteModal'
  | 'rejectApprovalFlow'
  | 'approveApprovalFlow';

type State = {
  scale: number,
  actionsModal: boolean,
  signModal: boolean,
  openModalName?: OpenModalNames,
  signatureStatus: 'disabled' | 'show' | 'hide',
  isShowGridBetaLabel: boolean,
  lastTouchedWidget: null | 'note' | 'email',
  isNoteWidgetActive: boolean,
  isPdfView: boolean,
  spreadZoom: number,
  isChangeApprovals: boolean,
  isShowJEForApprover: boolean,
  isSelectTableMode: boolean,
  selectTableHeaderRef: ?HTMLElement,
};

class DocumentPage extends React.PureComponent<Props, State> {
  isInitShowWarnings: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      scale: 1,
      signModal: false,
      signatureStatus: 'disabled',
      isShowGridBetaLabel: false,
      lastTouchedWidget: null,
      isNoteWidgetActive: false,
      isPdfView: true,
      spreadZoom: 1,
      isChangeApprovals: false,
      isShowJEForApprover: false,
      isSelectTableMode: false,
      selectTableHeaderRef: null,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    let update = {};
    const { signatureStatus } = state;
    const {
      document: { tags },
      isSignApprovalsStart,
    } = props;
    const isSigned = tags.has('_S_SIG_DOKKA1_SIGNED') || tags.has('_S_SIG_DOKKA2_SIGNED') || isSignApprovalsStart;
    if (isSigned && signatureStatus === 'disabled') {
      update = { ...update, signatureStatus: 'show' };
    }
    if (!isSigned) {
      update = { ...update, signatureStatus: 'disabled' };
    }
    return update;
  }

  componentDidMount() {
    const { isGranted } = this.props;
    if (this.isXls && checkIsAllowSpreadJSLicense() && !isGranted(ACL.IS_SUPPLIER_USER)) {
      this.setState({ isPdfView: false });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { loadViewArrangement, isGranted, justPublished } = this.props;

    if (isChangeProps(prevProps, this.props, ['userGUID', 'jeInvoiceType'])) {
      loadViewArrangement();
    }
    if (isChangeProps(prevProps, this.props, ['justPublished'])) {
      if (this.isBKCurrentApprovalForFinancialDoc && justPublished !== PublishTypes.PublishAndStay) {
        this.setState({ isShowJEForApprover: false });
      }
    }
    // This duplicates componentDidMount logic due to reason that at the moment
    // componentDidMount is invoked we still have previous document data, not the next one
    // so calculations for isPdfView might be donebased on previous document.
    // This logic checks if doc id has changed which guarantees this is invoked only once per doc navigation
    // and sort of mimics componentDidMount with new document data
    if (isChangeProps(prevProps, this.props, ['document', 'documentID'])) {
      if (this.isXls && checkIsAllowSpreadJSLicense() && !isGranted(ACL.IS_SUPPLIER_USER)) {
        this.setState({ isPdfView: false });
        // this extra check is to avoid setting isPdfView to true if its already true
        // this case covers navigating through pdf files only
      } else if (!this.state.isPdfView) {
        this.setState({ isPdfView: true });
      }
    }
  }

  onChangeViewArrangement = (newViewArrangement: TViewArrangement) => {
    const { viewArrangement, updateViewArrangement } = this.props;
    const { scale } = this.state;
    // eslint-disable-next-line max-len
    const newScale =
      FULL_WIDTH_VIEW.includes(viewArrangement) === FULL_WIDTH_VIEW.includes(newViewArrangement) ? scale : 1;
    this.setState({ scale: newScale });
    updateViewArrangement(newViewArrangement);
  };

  onAcceptDocument = (context: AcceptContext) => {
    const {
      documentAccept: accept,
      intl: { formatMessage },
      isStatement,
      documentScheduleAccept,
      isAllowRedirectAfterPublish,
      redirectToApprove,
    } = this.props;
    const { isShowJEForApprover } = this.state;
    const { force, remainOnDocument, type, userConfirmed, publishType } = context;

    if (type === 'common') {
      promisify(accept, {
        force,
        remainOnDocument: remainOnDocument || isShowJEForApprover,
        userConfirmed,
        publishType,
      })
        .then(() => {
          toast.info(
            formatMessage({
              id: 'document.actions.publish.success',
              defaultMessage: 'Document published',
            }),
          );

          if (isStatement) this.exportCsv();

          if (!remainOnDocument && !isShowJEForApprover) {
            if (isAllowRedirectAfterPublish) redirectToApprove();
            else this.nextDocument(this.backAction);
          }
        })
        .catch(() => {});
    } else if (type === 'schedule') {
      promisify(documentScheduleAccept, { userConfirmed, remainOnDocument: isShowJEForApprover })
        .then(() => {
          if (isStatement) this.exportCsv();

          if (!isShowJEForApprover) {
            if (isAllowRedirectAfterPublish) redirectToApprove();
            else this.nextDocument(this.backAction);
          }
        })
        .catch(() => {});
    }
  };

  get isBKCurrentApprovalForFinancialDoc(): boolean {
    const { isGranted, isCurrentUserSignaturePending } = this.props;
    return isGranted(ACL.IS_ACCOUNT) && isCurrentUserSignaturePending && this.isAclFinancial;
  }

  onScale = (mx: number) => {
    const scale = Math.max(Math.min(4, mx), 1);
    this.setState({
      scale,
    });
  };

  onZoom = (direction: 1 | -1 | 0) => {
    const { scale, spreadZoom, isPdfView } = this.state;
    const factor = 0.2;

    if (direction > 0) {
      if (isPdfView) {
        this.onScale(scale + factor);
      } else {
        this.setState({ spreadZoom: spreadZoom + factor });
      }
    } else if (isPdfView) {
      this.onScale(scale - factor);
    } else {
      this.setState({ spreadZoom: spreadZoom - factor });
    }
  };

  // TODO: maybe need to make one zoom variable (pdf excel),
  //  but I don't know how to behave in full screen mode
  getIsDisabledZoomButton = (direction: 1 | -1 | 0): boolean => {
    const { scale, spreadZoom, isPdfView } = this.state;
    const zoom = isPdfView ? scale : spreadZoom;
    switch (direction) {
      case 1:
        return zoom >= 4;
      case 0:
        return zoom === 1;
      case -1:
        return zoom <= 1;
      default:
        return true;
    }
  };

  onReset = () => {
    this.setState({
      scale: 1,
      spreadZoom: 1,
    });
  };

  onSignModalShow = () => this.setState({ signModal: true });

  onSignModalClose = () => this.setState({ signModal: false });

  onSign = (signatureID) => {
    const { signDocument, document } = this.props;

    signDocument({ signatureID, documentID: document.documentID });
  };

  // approve/reject approvals callback is invoked on resolve promise
  onManageApprovals = () => {
    if (this.isGoNext && this.nextDocument()) {
      this.nextDocument();
    }
  };

  onTouchedWidget = (widget: 'note' | 'email', e?: MouseEvent) => {
    if (e && e.button === 0) {
      window.getSelection().removeAllRanges();
    }
    this.setState({ lastTouchedWidget: widget });
  };

  setSelectTableHeaderRef = (ref: HTMLElement) => {
    this.setState({ selectTableHeaderRef: ref });
  };

  onEnableSelectTableMode = () => {
    this.setState({ isSelectTableMode: true });
  };

  onDisableSelectTableMode = () => {
    this.setState({ isSelectTableMode: false });
  };

  onSaveTableHeader = () => {
    const {
      headerTablesCorrection,
      intl: { formatMessage },
    } = this.props;

    if (this.state.selectTableHeaderRef) {
      const polygonContainers = this.state.selectTableHeaderRef.offsetParent.querySelectorAll('#Polygons-container');
      const selectRect = this.state.selectTableHeaderRef.getBoundingClientRect();

      if (polygonContainers.length) {
        const polygons = [...polygonContainers].filter((i) => {
          const { top, bottom } = i.getBoundingClientRect();
          return selectRect.top <= bottom && selectRect.bottom >= top;
        });

        if (polygons[0]?.childNodes.length) {
          const r = [...polygons[0].childNodes].filter((i) => {
            const { top, left, width, height } = i.getBoundingClientRect();
            const horizontalCenter = top + height / 2;
            const verticalCenter = left + width / 2;
            return (
              selectRect.left <= verticalCenter &&
              selectRect.top <= horizontalCenter &&
              selectRect.bottom >= horizontalCenter &&
              selectRect.right >= verticalCenter
            );
          });

          if (r.length) {
            const headerWords = [...r].map((i) => i.childNodes[0].ariaLabel);
            headerTablesCorrection({ headerWords });
            this.onDisableSelectTableMode();
          } else {
            toast.error(
              formatMessage({
                id: 'document.toast.selectTable.error.notWords',
                defaultMessage: 'Selection is incorrect - no words are included.',
              }),
            );
          }
        }
      }
    }
  };

  onNoteHandler = () => {
    const { state, openNoteWidget, closeNoteWidget } = this;
    if (state.isNoteWidgetActive) {
      closeNoteWidget();
      setTimeout(() => {
        openNoteWidget();
      }, 200);
    } else {
      openNoteWidget();
    }
  };

  get isLimitedBookkeeper(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_LIMITED_ACCOUNT);
  }

  get isReadOnlyBookkeeper(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_READ_ONLY_ACCOUNTANT);
  }

  get isConfidential(): boolean {
    const {
      document: { tags },
    } = this.props;
    return tags.has('_S_CONFIDENTIAL');
  }

  get backLink(): string | LocationShape {
    const {
      match: { params },
      backSearchUrl,
      document: { tags },
      isGranted,
    } = this.props;
    const isConfidential = tags.includes('_S_CONFIDENTIAL');

    if (isGranted(ACL.IS_SUPPLIER_USER)) {
      if (backSearchUrl) {
        return {
          pathname: generatePath(ROUTES_PATH.COMPANY_SUPPLIER.absolute, backSearchUrl.params),
          search: queryToSearch(backSearchUrl.query),
        };
      }

      return generatePath(ROUTES_PATH.COMPANY_SUPPLIER.absolute, { companyId: params.companyId });
    }

    if (backSearchUrl) {
      const path = isConfidential
        ? ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.absolute
        : ROUTES_PATH.COMPANY_WORKSPACE.absolute;

      return {
        pathname: generatePath(path, backSearchUrl.params),
        search: queryToSearch(backSearchUrl.query),
      };
    }
    const defaultPath = isConfidential
      ? ROUTES_PATH.COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY.absolute
      : ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.absolute;
    return generatePath(defaultPath, { companyId: params.companyId });
  }

  get isAclFinancial(): boolean {
    const { isGranted } = this.props;
    return isGranted(ACL.IS_WORK_ON_WITH_CURRENT_DOCUMENT_AS_FINANCIAL);
  }

  get isFinancial(): boolean {
    const { isGranted, isCurrentUserSignaturePending } = this.props;
    const { isShowJEForApprover } = this.state;
    if (isGranted(ACL.IS_SUPPLIER_USER)) {
      return false;
    }
    if (this.isLimitedBookkeeper || this.isReadOnlyBookkeeper) {
      return this.isAclFinancial;
    }
    if (isShowJEForApprover) {
      return isShowJEForApprover;
    }

    return this.isAclFinancial && !isCurrentUserSignaturePending;
  }

  get isGoNext() {
    const { document } = this.props;
    if (this.isLimitedBookkeeper || this.isReadOnlyBookkeeper) {
      return !this.isNextDocumentDisabled();
    }

    return (
      (!this.isAclFinancial || (this.isAclFinancial && document.status.includes('accepted'))) &&
      !this.isNextDocumentDisabled()
    );
  }

  get width() {
    const { view } = this.props;
    const documentsWidth = view.get('documentsWidth');
    const inFinWidth = window.innerHeight < mediaQuery.maxHeightNumber ? 380 : 560;
    const finWidth = documentsWidth - 40 < inFinWidth ? inFinWidth : documentsWidth - 40;
    return this.isFinancial ? finWidth : 800;
  }

  get isJeOnlyMode(): boolean {
    return this.viewArrangement === 'jeOnly';
  }

  get docControlsVisible(): boolean {
    const { isPdfView } = this.state;

    return !this.isJeOnlyMode && isPdfView;
  }

  get isXls(): boolean {
    const { document } = this.props;

    return getIsExcel(document.documentID);
  }

  get defaultFinancialView(): string {
    const { rtl, isJeLayoutRTL } = this.props;
    return rtl || isJeLayoutRTL ? 'rtl' : 'ltr';
  }

  get viewArrangement(): string {
    const { viewArrangement, document, jeInvoiceType, isGranted } = this.props;
    return this.isFinancial && document.doctype !== 'general' && jeInvoiceType && !isGranted(ACL.IS_SUPPLIER_USER)
      ? viewArrangement || this.defaultFinancialView
      : 'docOnly';
  }

  get isEditActionsBlocked() {
    const { isGranted } = this.props;

    return isGranted(ACL.IS_DOCUMENT_SCHEDULED_ACCEPTANCE);
  }

  getWidgetStyle = (widget: 'note' | 'email') => {
    const { lastTouchedWidget } = this.state;
    return {
      zIndex: lastTouchedWidget === widget ? 40 : 30,
    };
  };

  getEmail = () => {
    const { document, getDocumentEmailPaylod } = this.props;
    return new Promise((resolve, reject) => {
      getDocumentEmailPaylod({ documentID: document.documentID, resolve, reject });
    });
  };

  get isVisibleBody() {
    const { isSagaDataLoading } = this.props;
    return !isSagaDataLoading;
  }

  setManualIsActive = (arg) => {
    this.setState(typeof arg === 'function' ? arg : { isNoteWidgetActive: arg });
  };

  setIsChangeApprovals = () => {
    this.setState({ isChangeApprovals: true });
  };

  isUsePreventConfirmation = () => {
    const { isChangeApprovals } = this.state;
    const { approvalsStatus, approvalsAuthor, isApprovalsEnabled, userId } = this.props;
    const res = isApprovalsEnabled && approvalsStatus === 'draft' && approvalsAuthor === userId && isChangeApprovals;
    return res;
  };

  isCurrentDoc = () => {
    const { match, document } = this.props;
    return match.params.documentId === document.documentID;
  };

  exportCsv = () => {
    const {
      apiToken,
      document: { documentID },
      company: { id: companyId },
      locale,
    } = this.props;
    download(Api.getDocumentCsv({ dokkaToken: apiToken, documentID, companyId, language: locale }), documentID);
  };

  handleActionModal = (openModalName?: OpenModalNames) => (): void => {
    this.setState({ openModalName });
  };

  handleSignatureStatus = () => {
    this.setState((state) => ({ signatureStatus: state.signatureStatus === 'show' ? 'hide' : 'show' }));
  };

  handleViewMode = () => {
    this.setState((state) => ({ isPdfView: !state.isPdfView, scale: 1, spreadZoom: 1 }));
  };

  nextDocument = (defaultAction: () => void, direction = 1) => {
    const { goToNextDocument } = this.props;
    if (this.isNextDocumentDisabled()) {
      if (typeof defaultAction === 'function') {
        return defaultAction();
      }
      return null;
    }
    return goToNextDocument(direction);
  };

  backAction = () => {
    const { navigate } = this.props;
    navigate(this.backLink);
  };

  isNextDocumentDisabled = (): boolean => {
    const { navigation } = this.props;
    return !navigation.get('listLength') || navigation.get('listLength') < 2;
  };

  openNoteWidget = () => {
    this.setState({ isNoteWidgetActive: true });
  };

  closeNoteWidget = () => {
    this.setState({ isNoteWidgetActive: false });
  };

  showGridBetaLabel = (status: boolean) => {
    this.setState({ isShowGridBetaLabel: status });
  };

  changeDocumentView = () => {
    const { isShowJEForApprover } = this.state;
    const { setDocumentsJustPublished, justPublished, syncErp, document } = this.props;
    if (justPublished === PublishTypes.SchedulePublish) {
      syncErp({ documentID: document.documentID, reindexCache: false });
      setDocumentsJustPublished(null);
    }
    this.setState({ isShowJEForApprover: !isShowJEForApprover });
  };

  handleToggle2WayMatchingForDocument = () => {
    const { toggle2WayMatchingForDocument } = this.props;
    toggle2WayMatchingForDocument();
    this.onDisableSelectTableMode();
  };

  renderSignatureStatuses = createMemoFn(
    () => {
      const { document, classes, signings } = this.props;
      const signIsNecessary = checkAcceptSignType(document.documentID);
      const isAllowSignForCompany = signings.guid.length > 0 || signings.tokens.size > 0;

      return {
        document,
        classes,
        signIsNecessary,
        isAllowSignForCompany,
      };
    },

    ({ document, classes, signIsNecessary, isAllowSignForCompany }) => (
      <Box className={classes.signedContainer}>
        {isAllowSignForCompany &&
        signIsNecessary &&
        document.status.indexOf('signed') + 1 < 1 &&
        document.status.indexOf('pending') + 1 < 1 ? (
          <button
            type="button"
            className={classes.notSigned}
            disabled={document.protected}
            onClick={this.onSignModalShow}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="document.show.noSigned" defaultMessage="No E-Signature" />
            </Typography>
          </button>
        ) : null}
        {signIsNecessary && document.status.indexOf('pending') + 1 > 0 ? (
          <button type="button" className={cx(classes.notSigned, classes.pending)} onClick={this.onSignModalShow}>
            <Typography variant="subtitle2">
              <FormattedMessage id="document.show.pending" defaultMessage="Pending E-Signature" />
            </Typography>
          </button>
        ) : null}
      </Box>
    ),
  );

  render() {
    const {
      company,
      apiToken,
      view,
      document,
      doesCompanySupportGeneralDocsOnly,
      isStatement,
      locale,
      rtl,
      loading,
      reference,
      isCurrentUserSignaturePending,
      userId,
      isGranted,
      requestPopupChangeStatus,
      jePublicationWarnings,
      textractEnabledForDocument,
      textractForVendorEnabled,
      twoWayMatchingFeatureEnabled,
      classes,
    } = this.props;

    const {
      scale,
      signModal,
      openModalName,
      signatureStatus,
      isShowGridBetaLabel,
      isPdfView,
      spreadZoom,
      isNoteWidgetActive,
      isShowJEForApprover,
      isSelectTableMode,
    } = this.state;

    const isSupplier = isGranted(ACL.IS_SUPPLIER_USER);
    const isVisiblePdfViewToggle = this.isXls && checkIsAllowSpreadJSLicense() && !this.isJeOnlyMode && !isSupplier;
    const signatureToggleValue = signatureStatus === 'show' || signatureStatus === 'disabled';

    const isDisabled2WM = document.tags.contains(CONST.tags.isAccepted) || !textractForVendorEnabled;

    const isDisabledOrProcessing2WM =
      document.tags.contains(CONST.tags.isAccepted) ||
      document.tags.contains(CONST.tags.isBeingProcessed) ||
      !textractForVendorEnabled;

    const isVisibleChangeViewButton =
      isCurrentUserSignaturePending &&
      isGranted(ACL.IS_ACCOUNT) &&
      this.isAclFinancial &&
      !this.isLimitedBookkeeper &&
      !this.isReadOnlyBookkeeper;

    const isVisiblePublishButton =
      !this.isLimitedBookkeeper &&
      !this.isReadOnlyBookkeeper &&
      (!isCurrentUserSignaturePending || isShowJEForApprover);

    return !(document.documentID && apiToken) ? null : (
      <LayoutDocument backTo={this.backLink} hasConfirmBeforeLeave={this.isUsePreventConfirmation()}>
        <StickySubheader />
        {this.isCurrentDoc() && (
          <DocumentContainer isDrag={Boolean(reference.size)}>
            {loading && <CircularProgressWithBackdrop BackdropProps={{ sx: { position: 'absolute' } }} />}
            {this.isVisibleBody && (
              <Body
                locale={locale}
                view={view}
                viewArrangement={this.viewArrangement}
                isFinancial={this.isFinancial}
                document={document}
                onScale={this.onScale}
                scale={scale}
                rtl={rtl}
                signatureStatus={isSupplier ? 'hide' : signatureStatus}
                company={company}
                dokkaToken={apiToken}
                isStatement={isStatement}
                showGridBetaLabel={this.showGridBetaLabel}
                isShowGridBetaLabel={isShowGridBetaLabel}
                isPdfView={isPdfView}
                spreadZoom={spreadZoom}
                getIsDisabledZoomButton={this.getIsDisabledZoomButton}
                onZoom={this.onZoom}
                onReset={this.onReset}
                setIsChangeApprovals={this.setIsChangeApprovals}
                textractEnabledForDocument={textractEnabledForDocument && !isSelectTableMode}
                isSelectTableMode={isSelectTableMode}
                setSelectTableHeaderRef={this.setSelectTableHeaderRef}
              >
                <Stack
                  position="absolute"
                  justifyContent="center"
                  height="80vh"
                  spacing={1}
                  className={classes.widgetsContainer}
                  zIndex={30}
                >
                  {!isSupplier && this.renderSignatureStatuses()}
                  {document.fromEmail && (
                    <EmailWidget
                      style={this.getWidgetStyle('email')}
                      isReverse={this.viewArrangement === 'rtl'}
                      api={this.getEmail}
                      onMouseDown={(e) => this.onTouchedWidget('email', e)}
                    />
                  )}
                  {(Boolean(document.notes) || isNoteWidgetActive) && (
                    <NoteWidget
                      style={this.getWidgetStyle('note')}
                      isReverse={this.viewArrangement === 'rtl'}
                      manualIsActive={isNoteWidgetActive}
                      setManualIsActive={this.setManualIsActive}
                      content={document.notes}
                      noteColorKey={document.notesColor}
                      onMouseDown={() => this.onTouchedWidget('note')}
                    />
                  )}
                </Stack>
              </Body>
            )}

            {signModal && (
              <DialogSignDocument
                onSign={this.onSign}
                documents={Set.of(document)}
                signParams={company.get('signParams')}
                onClose={this.onSignModalClose}
              />
            )}

            <DocumentFooterPanel>
              <Stack direction="row" spacing={2} alignItems="center" divider={<FooterDivider />}>
                {this.isFinancial && (
                  <DocumentViewDropdown onSelect={this.onChangeViewArrangement} value={this.viewArrangement} />
                )}

                {isVisiblePdfViewToggle && (
                  <FormControlLabelSwitch
                    checked={isPdfView}
                    onChange={this.handleViewMode}
                    control={<Switch sx={{ ml: '0!important' }} />}
                    label={<FormattedMessage id="document.show.pdfView" defaultMessage="PDF view" />}
                    sx={{ ml: 0 }}
                  />
                )}

                {this.docControlsVisible && !isSupplier && (
                  <FormControlLabelSwitch
                    checked={signatureToggleValue}
                    onChange={this.handleSignatureStatus}
                    control={<Switch sx={{ ml: '0!important' }} />}
                    disabled={signatureStatus === 'disabled'}
                    label={<FormattedMessage id="document.show.showSignatures" defaultMessage="Show signatures" />}
                    sx={{ ml: 0 }}
                  />
                )}

                {this.isFinancial && twoWayMatchingFeatureEnabled && !isStatement && (!this.isXls || isPdfView) && (
                  <Tooltip
                    t={{
                      id: 'document.show.bottomBar.tooltip.2WayMatching',
                      defaultMessage: 'Enable the feature for the current vendor in Vendor Preferences',
                    }}
                    disabled={!isDisabled2WM}
                  >
                    <FormControlLabelSwitch
                      checked={textractEnabledForDocument}
                      onChange={this.handleToggle2WayMatchingForDocument}
                      control={<Switch sx={{ ml: '0!important' }} />}
                      label={
                        <FormattedMessage id="3WayMatching.featureToggler" defaultMessage="Enable 2-way-matching" />
                      }
                      disabled={isDisabledOrProcessing2WM}
                      sx={{ ml: 0 }}
                    />
                  </Tooltip>
                )}
                {textractEnabledForDocument && (!this.isXls || isPdfView) && (
                  <SelectTableButton
                    isSelectTableMode={isSelectTableMode}
                    onEnableSelectTableMode={this.onEnableSelectTableMode}
                    onDisableSelectTableMode={this.onDisableSelectTableMode}
                    onSave={this.onSaveTableHeader}
                  />
                )}
              </Stack>

              <Stack direction="row" ml="auto" pl={2} alignItems="center" spacing={2}>
                <DocumentMoreButtonDialogs
                  onReset={this.onReset}
                  company={company}
                  goToNextDocument={this.nextDocument}
                  document={document}
                  dokkaToken={apiToken}
                  backLink={this.backLink}
                  openModalName={openModalName}
                  handleActionModal={this.handleActionModal()}
                  disableGenerteJE={doesCompanySupportGeneralDocsOnly}
                  isCurrentUserSignaturePending={isCurrentUserSignaturePending}
                  onManageApprovals={this.onManageApprovals}
                  onNoteHandler={document.notes && this.onNoteHandler}
                  isEditActionsBlocked={this.isEditActionsBlocked}
                  isGoNext={this.isGoNext}
                />

                {isVisibleChangeViewButton && (
                  <>
                    <DocumentViewButton isShowJEForApprover={isShowJEForApprover} onClick={this.changeDocumentView} />
                    <FooterDivider />
                  </>
                )}

                {isVisiblePublishButton && (
                  <DocumentPublishButton
                    backLink={this.backLink}
                    onAcceptDocument={this.onAcceptDocument}
                    onToggleRequest={() => requestPopupChangeStatus(true)}
                    documentID={document.documentID}
                    isPending={this.isEditActionsBlocked}
                    userId={userId}
                    jePublicationWarnings={jePublicationWarnings}
                  />
                )}
                {isCurrentUserSignaturePending && !isShowJEForApprover && (
                  <ApprovalButtons
                    onManageApprovals={this.onManageApprovals}
                    onOpenRejectForm={this.handleActionModal('rejectApprovalFlow')}
                    onOpenApproveForm={this.handleActionModal('approveApprovalFlow')}
                  />
                )}
              </Stack>
            </DocumentFooterPanel>
          </DocumentContainer>
        )}
        <EventsHistoryPanel company={company} />
        <DialogKeyboardHotkeys />
      </LayoutDocument>
    );
  }
}

const mapStateToProps = (state, props: Props) => ({
  jePublicationWarnings: journalPublicationWarningsSelector(state),
  isStatement: isStatementSelector(state),
  loading: loadingSelector(state),
  company: companiesByIdSelector(state).get(props.match.params.companyId, CompanyFactory()),
  doesCompanySupportGeneralDocsOnly: doesCurrentCompanySupportGeneralDocsOnlySelector(state),
  document: documentSelector(state),
  view: documentViewSelector(state),
  locale: localeSelector(state),
  isGranted: ACL.isGranted(state),
  backSearchUrl: backSearchUrlSelector(state),
  navigation: navigationSelector(state),
  rtl: rtlEnable(state),
  isJeLayoutRTL: isJeLayoutRTLSelector(state),
  userId: userIdSelector(state),
  jeInvoiceType: jeInvoiceTypeSelector(state),
  signings: companySigningsSelector(state),
  isCurrentUserSignaturePending: isCurrentUserSignaturePendingSelector(state),
  isSignApprovalsStart: isSignApprovalsStartSelector(state),
  viewArrangement: viewArrangementSelector(state),
  reference: referenceSelector(state),
  approvalsStatus: approvalsStatusSelector(state),
  approvalsAuthor: approvalsAuthorSelector(state),
  isApprovalsEnabled: companyApprovalsFeatureEnabledSelector(state),
  isSagaDataLoading: sagaDataLoadingSelector(state),
  textractEnabledForDocument: textractEnabledForDocumentSelector(state),
  textractForVendorEnabled: textractForVendorEnabledSelector(state),
  twoWayMatchingFeatureEnabled: twoWayMatchingFeatureValueSelector(state),
  justPublished: justPublishedDocumentSelector(state),
});

const mapDispatchToProps = {
  documentAccept,
  documentScheduleAccept: documentScheduleAcceptAction,
  goToNextDocument: nextDocumentAction,
  signDocument: documentSignShowDocument,
  clearNavigation: clearNavigationAction,
  requestPopupChangeStatus: requestPopupChangeStatusAction,
  loadViewArrangement: documentGetViewArrangementAction,
  updateViewArrangement: documentUpdateViewArrangementAction,
  getDocumentEmailPaylod: getDocumentEmailPayloadAction,
  toggle2WayMatchingForDocument: toggle2WayMatchingForDocumentAction,
  setDocumentsJustPublished: documentsJustPublishedAction,
  syncErp: forceErpSyncAction,
  headerTablesCorrection: headerTablesCorrectionAction,
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sheet),
  withApiToken,
  withRedirectWhenApprovalsEnd,
)(DocumentPage);
