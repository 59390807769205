import React from 'react';
import { handleImport } from 'lib/import';
import ROUTES_PATH from 'domain/router/routesPathConfig';

import AppRoute from 'pages/index';
import RestrictedRoute from 'pages/RestrictedRoute';
import RedirectRoute from 'pages/redirect';
import MaintenanceRoute from 'pages/maintenance';
import ExpiredRoute from 'pages/expired';
import AccessLimitedRoute from 'pages/accessLimited';
import Route404 from 'pages/servicePage/page404';
import DocumentNotFoundRoute from 'pages/servicePage/documentNotFound';
import BrowserNotSupportedRoute from 'pages/notSupported';
import IpadNotSupportedRoute from 'pages/notSupported/ipad';
import MobileBrowserNotSupportedRoute from 'pages/notSupported/mobile';

import AuthRoute from 'pages/authPage';
import ForgotPasswordRoute from 'pages/recovery';
import ResetPasswordRoute from 'pages/recovery/reset';
import SetPasswordRoute from 'pages/setpasswd';
import PLARoute from 'pages/authPage/PasswordLessAuth/screens/login';
import PLAAccessSuccessRoute from 'pages/authPage/PasswordLessAuth/screens/success';
import IDPRoute from 'pages/idp';

import OrganizationsRoute from 'pages/organizations';

import CompaniesRoute from 'pages/companies';

import CompanyRoute from 'pages/company/standard';
import CompanyConfidentialRoute from 'pages/company/confidential';
import CompanySupplierRoute from 'pages/supplierCompany';

import DocumentRoute from 'pages/document';
import RequestDocumentTransactionRoute from 'pages/requestTransaction';

import ConfigurationsOrganizationRoute from 'pages/configurations/organization';
import OrganizationInfoRoute from 'pages/configurations/organization/pages/info';
import OrganizationInfoMainRoute from 'pages/configurations/organization/pages/info/forms/main';
import OrganizationInfoContactRoute from 'pages/configurations/organization/pages/info/forms/contact';
import OrganizationInfoFinancialRoute from 'pages/configurations/organization/pages/info/forms/financial';
import OrganizationInfoFeaturesRoute from 'pages/configurations/organization/pages/info/forms/features';
import OrganizationBillingsRoute from 'pages/configurations/organization/pages/billings';
import OrganizationConnectionsRoute from 'pages/configurations/organization/pages/users';
import OrganizationSecurityRoute from 'pages/configurations/organization/pages/security';
import OrganizationBackupsRoute from 'pages/configurations/organization/pages/backups';
import OrganizationKeysRoute from 'pages/configurations/organization/pages/keys';

import ConfigurationsCompanyRoute from 'pages/configurations/company';
import CompanyGeneralRoute from 'pages/configurations/company/pages/general';
import CompanyGeneralMainRoute from 'pages/configurations/company/pages/general/forms/main';
import CompanyGeneralFinancialRoute from 'pages/configurations/company/pages/general/forms/financial';
import CompanyGeneralFeaturesRoute from 'pages/configurations/company/pages/general/forms/features';
import CompanyUsersRoute from 'pages/configurations/company/pages/users';
import CompanyIntegrationsRoute from 'pages/configurations/company/pages/integrations';
import CompanyApplicationsRoute from 'pages/configurations/company/pages/applications';
import CompanySupplierDefaultsRoute from 'pages/configurations/company/pages/supplier_defaults';
import CompanyApprovalsRoute from 'pages/configurations/company/pages/approvals';

import ProfileRoute from 'pages/profiles/company';
import ProfileMainRoute from 'pages/profiles/company/pages/info';
import ProfileNotificationsRoute from 'pages/profiles/company/pages/notifications';
import ProfilePreferencesRoute from 'pages/profiles/company/pages/preferences';
import ProfileAliasesRoute from 'pages/profiles/company/pages/aliases';

import MobileApprovalsRoute from 'pages/mobile/approvals';

import ErrorElement from 'lib/errorLogger/ErrorElement';

import { cognitoAuthAppUrl } from 'amplify/awsauth';

const routes = [
  {
    id: ROUTES_PATH.ROOT_LAYOUT.id,
    element: <AppRoute />,
    restriction: ROUTES_PATH.ROOT_LAYOUT.restriction,
    errorElement: <ErrorElement />,
    children: [
      {
        id: ROUTES_PATH.COMPANIES.id,
        path: ROUTES_PATH.COMPANIES.relative,
        index: true,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANIES.restriction}>
            <CompaniesRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/companies/sagas')),
      },
      {
        id: ROUTES_PATH.ACCESS_LIMITED.id,
        path: ROUTES_PATH.ACCESS_LIMITED.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.ACCESS_LIMITED.restriction}>
            <AccessLimitedRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.DOCUMENT_NOT_FOUND.id,
        path: ROUTES_PATH.DOCUMENT_NOT_FOUND.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.DOCUMENT_NOT_FOUND.restriction}>
            <DocumentNotFoundRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.NOT_SUPPORTED_IPAD.id,
        path: ROUTES_PATH.NOT_SUPPORTED_IPAD.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.NOT_SUPPORTED_IPAD.restriction}>
            <IpadNotSupportedRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.NOT_SUPPORTED_BROWSER.id,
        path: ROUTES_PATH.NOT_SUPPORTED_BROWSER.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.NOT_SUPPORTED_BROWSER.restriction}>
            <BrowserNotSupportedRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.id,
        path: ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.NOT_SUPPORTED_MOBILE_BROWSER.restriction}>
            <MobileBrowserNotSupportedRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.REQUEST_DOCUMENT.id,
        path: ROUTES_PATH.REQUEST_DOCUMENT.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.REQUEST_DOCUMENT.restriction}>
            <RequestDocumentTransactionRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/requestTransaction/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_IDP_LOGIN.id,
        path: ROUTES_PATH.AUTH_IDP_LOGIN.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_IDP_LOGIN.restriction}>
            <IDPRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/idp/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_RESET_PASSWORD.id,
        path: ROUTES_PATH.AUTH_RESET_PASSWORD.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_RESET_PASSWORD.restriction}>
            <ResetPasswordRoute />
          </RestrictedRoute>
        ),
        // this allows user to reset passwd being loggedin on mobile
        // while password was requested from web
        saga: () => handleImport(import('pages/recovery/reset/saga')),
      },
      {
        id: ROUTES_PATH.AUTH_SET_PASSWORD.id,
        path: ROUTES_PATH.AUTH_SET_PASSWORD.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_SET_PASSWORD.restriction}>
            <SetPasswordRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/setpasswd/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_PLA_LOGIN.id,
        path: ROUTES_PATH.AUTH_PLA_LOGIN.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_PLA_LOGIN.restriction}>
            <PLARoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/authPage/PasswordLessAuth/screens/login/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_PLA_ACCESS_SUCCESS.id,
        path: ROUTES_PATH.AUTH_PLA_ACCESS_SUCCESS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_PLA_ACCESS_SUCCESS.restriction}>
            <PLAAccessSuccessRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.AUTH_FORGOT_PASSWORD.id,
        path: ROUTES_PATH.AUTH_FORGOT_PASSWORD.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_FORGOT_PASSWORD.restriction}>
            <ForgotPasswordRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/recovery/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_LOGIN.id,
        path: ROUTES_PATH.AUTH_LOGIN.relative,
        loader: () => {
          window.location = cognitoAuthAppUrl;
        },
      },
      {
        id: ROUTES_PATH.MAINTENANCE.id,
        path: ROUTES_PATH.MAINTENANCE.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.MAINTENANCE.restriction}>
            <MaintenanceRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/maintenance/sagas')),
      },
      {
        id: ROUTES_PATH.AUTH_LOGOUT.id,
        path: ROUTES_PATH.AUTH_LOGOUT.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.AUTH_LOGOUT.restriction}>
            <AuthRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/logout/saga')),
      },
      {
        id: ROUTES_PATH.REDIRECT.id,
        path: ROUTES_PATH.REDIRECT.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.REDIRECT.restriction}>
            <RedirectRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/redirect/sagas')),
      },
      {
        id: ROUTES_PATH.DOCUMENT_MOBILE_APPROVALS.id,
        path: ROUTES_PATH.DOCUMENT_MOBILE_APPROVALS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.DOCUMENT_MOBILE_APPROVALS.restriction}>
            <MobileApprovalsRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/mobile/approvals/sagas')),
      },

      {
        id: ROUTES_PATH.ORGANIZATIONS.id,
        path: ROUTES_PATH.ORGANIZATIONS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATIONS.restriction}>
            <OrganizationsRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/organizations/sagas')),
      },
      {
        id: ROUTES_PATH.COMPANY_SUPPLIER.id,
        path: ROUTES_PATH.COMPANY_SUPPLIER.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SUPPLIER.restriction}>
            <CompanySupplierRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/supplierCompany/sagas')),
      },
      {
        id: ROUTES_PATH.DOCUMENT.id,
        path: ROUTES_PATH.DOCUMENT.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.DOCUMENT.restriction}>
            <DocumentRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/document/sagas')),
      },
      {
        id: ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.id,
        path: ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_DEFAULT_CATEGORY.restriction}>
            <div />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/company/redirectSaga')),
      },
      {
        id: ROUTES_PATH.COMPANY_WORKSPACE.id,
        path: ROUTES_PATH.COMPANY_WORKSPACE.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_WORKSPACE.restriction}>
            <CompanyRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/company/sagas')),
      },
      {
        id: ROUTES_PATH.COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY.id,
        path: ROUTES_PATH.COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_CONFIDENTIAL_DEFAULT_CATEGORY.restriction}>
            <div />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/company/redirectConfidentialSaga')),
      },
      {
        id: ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.id,
        path: ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_CONFIDENTIAL_WORKSPACE.restriction}>
            <CompanyConfidentialRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/company/sagas')),
        sagasProps: {
          companyType: 'confidential',
        },
      },
      // ORGANIZATION SETTINGS
      {
        id: ROUTES_PATH.ORGANIZATION_SETTINGS.id,
        path: ROUTES_PATH.ORGANIZATION_SETTINGS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS.restriction}>
            <ConfigurationsOrganizationRoute />
          </RestrictedRoute>
        ),
        children: [
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_BILLINGS.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_BILLINGS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_BILLINGS.restriction}>
                <OrganizationBillingsRoute />
              </RestrictedRoute>
            ),
          },
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_USERS.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_USERS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_USERS.restriction}>
                <OrganizationConnectionsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/organization/pages/users/sagas')),
          },
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_SECURITY.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_SECURITY.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_SECURITY.restriction}>
                <OrganizationSecurityRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/organization/pages/security/sagas')),
          },
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_BACKUPS.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_BACKUPS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_BACKUPS.restriction}>
                <OrganizationBackupsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/organization/pages/backups/sagas')),
          },
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL.restriction}>
                <OrganizationInfoRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/organization/pages/info/sagas')),
            children: [
              {
                id: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.id,
                path: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_MAIN.restriction}>
                    <OrganizationInfoMainRoute />
                  </RestrictedRoute>
                ),
              },
              {
                id: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_CONTACT.id,
                path: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_CONTACT.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_CONTACT.restriction}>
                    <OrganizationInfoContactRoute />
                  </RestrictedRoute>
                ),
              },
              {
                id: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FINANCIAL.id,
                path: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FINANCIAL.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FINANCIAL.restriction}>
                    <OrganizationInfoFinancialRoute />
                  </RestrictedRoute>
                ),
              },
              {
                id: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FEATURES.id,
                path: ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FEATURES.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_GENERAL_FEATURES.restriction}>
                    <OrganizationInfoFeaturesRoute />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            id: ROUTES_PATH.ORGANIZATION_SETTINGS_API_KEYS.id,
            path: ROUTES_PATH.ORGANIZATION_SETTINGS_API_KEYS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.ORGANIZATION_SETTINGS_API_KEYS.restriction}>
                <OrganizationKeysRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/organization/pages/keys/sagas')),
          },
        ],
      },
      // COMPANY SETTINGS
      {
        id: ROUTES_PATH.COMPANY_SETTINGS.id,
        path: ROUTES_PATH.COMPANY_SETTINGS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS.restriction}>
            <ConfigurationsCompanyRoute />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/configurations/company/sagas')),
        children: [
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_GENERAL.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_GENERAL.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_GENERAL.restriction}>
                <CompanyGeneralRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/general/sagas')),
            children: [
              {
                id: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.id,
                path: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_GENERAL_MAIN.restriction}>
                    <CompanyGeneralMainRoute />
                  </RestrictedRoute>
                ),
              },
              {
                id: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FINANCIAL.id,
                path: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FINANCIAL.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FINANCIAL.restriction}>
                    <CompanyGeneralFinancialRoute />
                  </RestrictedRoute>
                ),
              },
              {
                id: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FEATURES.id,
                path: ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FEATURES.relative,
                element: (
                  <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_GENERAL_FEATURES.restriction}>
                    <CompanyGeneralFeaturesRoute />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_USERS.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_USERS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_USERS.restriction}>
                <CompanyUsersRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/users/sagas')),
          },
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_INTEGRATIONS.restriction}>
                <CompanyIntegrationsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/integrations/sagas')),
          },
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_APPLICATIONS.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_APPLICATIONS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_APPLICATIONS.restriction}>
                <CompanyApplicationsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/applications/sagas')),
          },
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_SUPPLIER_DEFAULTS.restriction}>
                <CompanySupplierDefaultsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/supplier_defaults/sagas')),
          },
          {
            id: ROUTES_PATH.COMPANY_SETTINGS_APPROVALS.id,
            path: ROUTES_PATH.COMPANY_SETTINGS_APPROVALS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.COMPANY_SETTINGS_APPROVALS.restriction}>
                <CompanyApprovalsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/configurations/company/pages/approvals/sagas')),
          },
        ],
      },
      // ACCOUNT SETTINGS
      {
        id: ROUTES_PATH.PROFILE_SETTINGS.id,
        path: ROUTES_PATH.PROFILE_SETTINGS.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.PROFILE_SETTINGS.restriction}>
            <ProfileRoute />
          </RestrictedRoute>
        ),
        saga: () => import('pages/profiles/company/sagas'),
        children: [
          {
            id: ROUTES_PATH.PROFILE_SETTINGS_MAIN.id,
            path: ROUTES_PATH.PROFILE_SETTINGS_MAIN.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.PROFILE_SETTINGS_MAIN.restriction}>
                <ProfileMainRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/profiles/company/pages/info/sagas')),
          },
          {
            id: ROUTES_PATH.PROFILE_SETTINGS_NOTIFICATIONS.id,
            path: ROUTES_PATH.PROFILE_SETTINGS_NOTIFICATIONS.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.PROFILE_SETTINGS_NOTIFICATIONS.restriction}>
                <ProfileNotificationsRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/profiles/company/pages/notifications/sagas')),
          },
          {
            id: ROUTES_PATH.PROFILE_SETTINGS_PREFERENCES.id,
            path: ROUTES_PATH.PROFILE_SETTINGS_PREFERENCES.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.PROFILE_SETTINGS_PREFERENCES.restriction}>
                <ProfilePreferencesRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/profiles/company/pages/preferences/sagas')),
          },
          {
            id: ROUTES_PATH.PROFILE_SETTINGS_ALIASES.id,
            path: ROUTES_PATH.PROFILE_SETTINGS_ALIASES.relative,
            element: (
              <RestrictedRoute restriction={ROUTES_PATH.PROFILE_SETTINGS_ALIASES.restriction}>
                <ProfileAliasesRoute />
              </RestrictedRoute>
            ),
            saga: () => handleImport(import('pages/profiles/company/pages/aliases/sagas')),
          },
        ],
      },
      {
        id: ROUTES_PATH.EXPIRED.id,
        path: ROUTES_PATH.EXPIRED.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.EXPIRED.restriction}>
            <ExpiredRoute />
          </RestrictedRoute>
        ),
      },
      {
        id: ROUTES_PATH.PAGE404.id,
        path: ROUTES_PATH.PAGE404.relative,
        element: (
          <RestrictedRoute restriction={ROUTES_PATH.PAGE404.restriction}>
            <Route404 />
          </RestrictedRoute>
        ),
        saga: () => handleImport(import('pages/servicePage/sagas')),
      },
    ],
  },
];

export default routes;
